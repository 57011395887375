import React, { useState, useEffect } from "react"
import { Box, Hidden, CircularProgress, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { navigationValues } from "utils/navigationValues"
import { LogoIcon } from "assets"

const IMAGE_SIZE = 60

const useStyles = makeStyles(() => ({
  "@keyframes icon-pulsate": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@-webkit-keyframes icon-pulsate": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  root: {
    position: "fixed",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  wrapper: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: IMAGE_SIZE * 4 * 2,
    width: IMAGE_SIZE * 4 * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    position: "fixed",
    top: "60%",
    fontSize: "1.1rem",
    textAlign: "center",
    width: "90vw",
  },
  logoIcon: {
    width: "80px",
    height: "80px",
    animation: "$icon-pulsate 2s infinite",
    "-webkit-animation": "$icon-pulsate 2s infinite",
  },
}))

const getLoadingText = (navigationValue) => {
  switch (navigationValue) {
    case navigationValues.HOME:
      return "Laddar ditt hem..."
    case navigationValues.VIDEO:
      return "Laddar..."
    case navigationValues.MESSAGES:
      return "Laddar meddelanden..."
    default:
      return "Laddar..."
  }
}

const LoadingScreen = ({ screen }) => {
  const classes = useStyles()
  const [loadingText, setLoadingText] = useState(getLoadingText(screen))

  useEffect(() => {
    let intervalCount = 0
    const interval = setInterval(() => {
      intervalCount += 1
      if (intervalCount === 2) {
        setLoadingText("Ursäkta dröjsmålet, laddas strax…")
        clearInterval(interval)
      } else {
        setLoadingText("Var god vänta…")
      }
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <CircularProgress size={50} color="primary" />
      </Hidden>
      <Hidden smUp>
        <Box className={classes.wrapper}>
          <LogoIcon className={classes.logoIcon} />
        </Box>
        <Typography className={classes.text}>{loadingText}</Typography>
      </Hidden>
    </Box>
  )
}

export default LoadingScreen
