import React, { useEffect, useState, useContext } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import share from "assets/share.png"
import dots from "assets/dots.png"
import { Logo } from "assets"
import {
  isFirefox,
  isIOS,
  isMobile,
  mobileModel,
  isDesktop,
  isChrome,
  isAndroid,
  isSafari,
} from "react-device-detect"
import { differenceInDays } from "date-fns"
import { beforeInstallPromptContext } from "contexts"

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  dialogContentText: {
    maxWidth: "300px",
    textAlign: "center",
  },
  logoWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Montserrat",
    fontSize: "22px",
    fontWeight: "700",
    margin: "30px 0 15px",
    "& span": {
      color: theme.palette.primary.main,
      paddingLeft: "5px",
    },
    "& img": {
      marginRight: "5px",
    },
  },
  shareIcon: {
    width: "25px",
    margin: "0 5px",
    position: "relative",
    top: "5px",
  },
  shareIconDots: {
    width: "20px",
    padding: "0 2px",
    position: "relative",
    top: "5px",
  },
  dialogContentTextIOS: {
    textAlign: "center",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.9)",
    marginTop: "15px",
  },
}))

const getBrowserLanguage = () => {
  const firstLanguage = window.navigator?.languages?.length
    ? window.navigator.languages[0]
    : null
  const browserLanguage =
    firstLanguage ||
    window.navigator?.language ||
    window.navigator?.browserLanguage ||
    window.navigator?.userLanguage

  return browserLanguage
}

export const AddToHomeScreen = ({
  pastSessionCount,
  videoMeetingStart,
  openDialog,
  closeDialog,
}) => {
  const {
    actionWrapper,
    dialogContentText,
    logoWrap,
    shareIcon,
    shareIconDots,
    dialogContentTextIOS,
  } = useStyles()

  const browserLanguage = getBrowserLanguage()
  const isEnglishLanguageUsedInBrowser = browserLanguage?.includes("en")
  const iPad = isMobile && isIOS && mobileModel !== "iPhone"
  const desktop = isDesktop && !iPad
  const SESSION_NUMBERS_WHEN_DIALOG_SHOULD_BE_SHOWN = [1, 3, 7]
  const ADD_TO_HOMESCREEN_SHOWN_AT = "addToHomeScreenShownAt"

  const { deferredPrompt, setDeferredPrompt } = useContext(
    beforeInstallPromptContext
  )
  const [shouldShowDialog, setShouldShowDialog] = useState(false)

  const handleAddToHomeScreen = () => {
    const sessionNumber = pastSessionCount + 1
    const minDaysBeforeNextDisplay = 1
    const shownAt = localStorage.getItem(ADD_TO_HOMESCREEN_SHOWN_AT)

    if (
      SESSION_NUMBERS_WHEN_DIALOG_SHOULD_BE_SHOWN.includes(sessionNumber) &&
      (!shownAt ||
        differenceInDays(new Date(), new Date(shownAt)) >=
          minDaysBeforeNextDisplay)
    ) {
      setShouldShowDialog(true)
      localStorage.setItem(ADD_TO_HOMESCREEN_SHOWN_AT, new Date())
    }
  }

  useEffect(() => {
    if (openDialog) {
      handleAddToHomeScreen()
    } else {
      setShouldShowDialog(false)
    }
  }, [openDialog, pastSessionCount])

  const onAddToHomeScreenButtonClick = (e) => {
    e.preventDefault()
    closeDialog()

    if (deferredPrompt) {
      deferredPrompt.prompt()
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome !== "accepted") {
          setDeferredPrompt(null)
        }
      })
    }
  }

  if (
    desktop ||
    (isMobile && isIOS && isChrome) ||
    (isAndroid && isChrome && !deferredPrompt) ||
    !videoMeetingStart ||
    !openDialog ||
    !shouldShowDialog
  ) {
    return null
  }

  return (
    <Dialog
      open={openDialog}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeDialog()
        }
      }}
    >
      <div className={logoWrap}>
        <Logo />
      </div>
      <DialogContent>
        <DialogContentText className={dialogContentText}>
          Installera den här applikationen på din startskärm för en bättre
          upplevelse.
        </DialogContentText>

        {isIOS && isSafari && (
          <DialogContentText className={dialogContentTextIOS}>
            <>
              Tryck
              <img className={shareIcon} src={share} alt="Share Icon" />
              sedan{" "}
              {isEnglishLanguageUsedInBrowser
                ? "'Add to Home Screen'"
                : "'Lägg till på hemskärmen'"}
            </>
          </DialogContentText>
        )}

        {isAndroid && isFirefox && (
          <DialogContentText className={dialogContentTextIOS}>
            <>
              Tryck
              <img className={shareIconDots} src={dots} alt="Share Icon" />
              sedan{" "}
              {isEnglishLanguageUsedInBrowser
                ? "'Add to Home screen' eller 'Install'"
                : "'Lägg till på startsidan' eller 'Installera'"}
            </>
          </DialogContentText>
        )}
      </DialogContent>

      {isAndroid && isChrome && (
        <DialogActions className={actionWrapper}>
          <Button variant="contained" onClick={onAddToHomeScreenButtonClick}>
            Lägg till på startskärmen
          </Button>
        </DialogActions>
      )}

      <DialogActions className={actionWrapper}>
        <Button onClick={closeDialog}>Stäng</Button>
      </DialogActions>
    </Dialog>
  )
}
