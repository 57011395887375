import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Box } from "@mui/material"
import { fetchCaregiverAbsence as getCaregiverAbsence } from "redux/caregiver"
import { differenceInCalendarDays } from "date-fns"
import { makeStyles } from "@mui/styles"
import Banner from "./Banner"

const bannerType = {
  ABSENCE: "absence",
  MEETING: "meeting",
}

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      top: theme.spacing(8),
      zIndex: 1,
    },
  },
}))

const Banners = ({
  caregiverId,
  caregiverFirstName,
  absence,
  videoMeeting,
  fetchCaregiverAbsence,
  freeCard,
  socialSecurity,
}) => {
  const [daysUntilAbsence, setDaysUntilAbsence] = useState(null)
  const [absenceDuration, setAbsenceDuration] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    const loadAbsenceData = async () => {
      if (caregiverId) {
        await fetchCaregiverAbsence({
          caregiverId,
          excludeEnded: true,
        })
      }
    }
    loadAbsenceData()
  }, [caregiverId])

  useEffect(() => {
    const absenceHandler = () => {
      if (absence) {
        setDaysUntilAbsence(
          differenceInCalendarDays(new Date(absence.startAt), new Date())
        )
        setAbsenceDuration(
          differenceInCalendarDays(
            new Date(absence.endAt),
            new Date(absence.startAt)
          )
        )
      }
    }
    absenceHandler()
  }, [absence?.startAt, absence?.endAt])

  return (
    <Box className={classes.bannerContainer}>
      {videoMeeting?.start && (
        <Banner
          videoMeetingPaymentLink={videoMeeting.paymentLink}
          videoMeetingHasCustomPaymentExpiration={
            videoMeeting.hasCustomPaymentExpiration
          }
          type={bannerType.VIDEO}
          bannerType={bannerType}
          videoMeetingId={videoMeeting.id}
          videoMeetingStart={videoMeeting.start}
          videoMeetingCreatedTime={videoMeeting.createdAt}
          videoMeetingIsPaid={videoMeeting.paid}
          freeCard={freeCard}
          socialSecurity={socialSecurity}
        />
      )}
      {absence && daysUntilAbsence <= 3 && (
        <Banner
          caregiverFirstName={caregiverFirstName}
          absence={absence}
          daysUntilAbsence={daysUntilAbsence}
          absenceDuration={absenceDuration}
          type={bannerType.ABSENCE}
          bannerType={bannerType}
        />
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { caregiver, user } = state
  const { data: caregiverData, absence } = caregiver
  const { firstName: caregiverFirstName, id: caregiverId } = caregiverData
  const { meeting: videoMeeting } = user
  return {
    caregiverId,
    caregiverFirstName,
    absence,
    videoMeeting,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCaregiverAbsence: (caregiverId) =>
    dispatch(getCaregiverAbsence(caregiverId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Banners)
