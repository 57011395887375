import React, { useEffect, useState, useCallback, useRef } from "react"
import { connect } from "react-redux"
import { Box, Card, CardMedia } from "@mui/material"
import { fetchCaregiver as getCaregiver } from "redux/caregiver"
import store from "redux/store"
import {
  setSessionCount as putSessionCount,
  setTreatmentStageImage as putTreatmentStageImage,
  showAlert,
} from "redux/common"
import { getUserSessionCount } from "api/getUserSessionCount"
import getTreatmentStageImage from "utils/getTreatmentStageImage"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@mui/material/styles"
import { AddToHomeScreen } from "components/AddToHomeScreen"
import { useSearchParams, useNavigate } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen"
import { navigationValues } from "utils/navigationValues"
import { isAndroid } from "react-device-detect"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      overflow: "unset",
      height: "100%",
      paddingBottom: theme.spacing(7.5),
    },
  },
  content: {
    padding: theme.spacing(7),
    boxSizing: "border-box",
    flex: 4,
    overflowY: "auto",
    height: "calc(100vh - 64px)",
    [theme.breakpoints.up("lg")]: {
      borderRight: `0.5px solid ${theme.palette.gray}`,
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
      flex: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 3, 10.5, 3),
      overflowY: "unset",
      height: "100%",
    },
  },
  imageCard: {
    boxShadow: "none",
    [theme.breakpoints.up("lg")]: {
      width: "90%",
    },
  },
}))

const Home = ({
  userId,
  caregiver,
  sessionCount,
  videoMeeting,
  treatmentStageImage,
  setSessionCount,
  setTreatmentStageImage,
  fetchCaregiver,
}) => {
  const navigate = useNavigate()
  const navigateToVideoPageSessionItem = sessionStorage.getItem(
    "navigateToVideoPage"
  )
  if (
    navigateToVideoPageSessionItem &&
    navigateToVideoPageSessionItem === "true"
  ) {
    navigate("/video")
  }
  const classes = useStyles()
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const afterPaymentParam = searchParams.get("after-payment")
  const { data: caregiverData, loading } = caregiver
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  )
  const [showAddToHomeScreenDialog, setShowAddToHomeScreenDialog] =
    useState(false)
  const exitConfirmation = useRef(false)

  const handleWindowResize = useCallback(() => {
    setIsMobileView(window.innerWidth < theme.breakpoints.values.sm)
  }, [])

  useEffect(() => {
    const loadData = async () => {
      setTreatmentStageImage(null)
      if (userId) {
        if (!caregiverData.id) {
          await fetchCaregiver(userId)
        } else {
          const { data: totalSessionCount } = await getUserSessionCount(
            userId,
            caregiverData.id
          )
          const treatmentImage = getTreatmentStageImage(
            totalSessionCount,
            isMobileView
          )
          setSessionCount(totalSessionCount)
          setTreatmentStageImage(treatmentImage)
          window.addEventListener("resize", handleWindowResize)
          return () => {
            window.removeEventListener("resize", handleWindowResize)
          }
        }
      }
    }
    loadData()
  }, [userId, caregiverData?.id, handleWindowResize, isMobileView])

  useEffect(
    () => () => {
      window.scrollTo({ top: 0 })
    },
    []
  )

  useEffect(() => {
    if (sessionCount) {
      const treatmentImage = getTreatmentStageImage(sessionCount, isMobileView)
      setTreatmentStageImage(treatmentImage)
    }
  }, [sessionCount])

  useEffect(() => {
    if (afterPaymentParam && isAndroid) {
      setInterval(() => {
        setShowAddToHomeScreenDialog(true)
      }, 500)
    }
    setShowAddToHomeScreenDialog(false)
  }, [afterPaymentParam])

  useEffect(() => {
    const handlePopstate = () => {
      if (!exitConfirmation.current) {
        exitConfirmation.current = true

        // Show your exit confirmation message
        store.dispatch(
          showAlert({
            type: "info",
            message: "Tryck bakåt igen för att avsluta",
            duration: 10000,
          })
        )
        navigate("/")

        setTimeout(() => {
          exitConfirmation.current = false
        }, 10000)
      } else {
        navigate(-1)
      }
    }
    if (isMobileView) {
      window.addEventListener("popstate", handlePopstate)
      navigate("/")
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("popstate", handlePopstate)
      }
    }
  }, [isMobileView])

  return (
    <Box className={classes.root}>
      {loading || !treatmentStageImage ? (
        <LoadingScreen screen={navigationValues.HOME} />
      ) : (
        <Box className={classes.content}>
          <Card className={classes.imageCard}>
            <CardMedia component="img" image={treatmentStageImage} />
          </Card>
          <AddToHomeScreen
            openDialog={showAddToHomeScreenDialog}
            closeDialog={() => {
              setShowAddToHomeScreenDialog(false)
            }}
            pastSessionCount={sessionCount}
            videoMeetingStart={videoMeeting?.start}
          />
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  const { user, caregiver, common } = state
  const { id: userId, meeting: videoMeeting } = user
  const { sessionCount, treatmentStageImage } = common
  return {
    userId,
    caregiver,
    sessionCount,
    treatmentStageImage,
    videoMeeting,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCaregiver: (userId) => dispatch(getCaregiver(userId)),
  setSessionCount: (sessionCount) => dispatch(putSessionCount(sessionCount)),
  setTreatmentStageImage: (treatmentStageImage) =>
    dispatch(putTreatmentStageImage(treatmentStageImage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
