import { isDate, isBefore, isSameDay } from "date-fns"

/**
 *
 * @param {string|number} expirationDate
 * @param {string|number} [checkAgainstDate]
 * @returns {boolean}
 */

function isFreeCardValid(expirationDate, checkAgainstDate = Date.now()) {
  if (!expirationDate) {
    return false
  }
  const endDate = new Date(expirationDate)
  const comparableDate = new Date(checkAgainstDate)

  if (!isDate(endDate) || !isDate(comparableDate)) {
    return false
  }

  return isBefore(comparableDate, endDate) || isSameDay(comparableDate, endDate)
}

export default isFreeCardValid
