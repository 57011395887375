import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  unmatchButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
      color: theme.palette.white,
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const UnmatchCaregiverConfirmationDialog = ({
  onClose,
  openDialog,
  onUnmatch,
  error,
  hasActiveSession,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={openDialog} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        Byt psykolog
        {hasActiveSession && (
          <Typography
            variant="caption"
            component="p"
            sx={(theme) => ({
              color: theme.palette.warning.main,
            })}
          >
            Om du har ett aktivt chattbesök så kommer det avslutas
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        Detta tar dig tillbaka till sidan där du väljer psykolog. Är du säker?
        {error && (
          <Typography
            variant="caption"
            component="p"
            className={classes.errorMessage}
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Avbryt
        </Button>
        <Button onClick={onUnmatch} className={classes.unmatchButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnmatchCaregiverConfirmationDialog
