import React, { useEffect, useState, useCallback } from "react"
import { connect } from "react-redux"
import Onboarding from "pages/onBoarding"
import { CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import EmptyHeader from "components/EmptyHeader"
import Sidebar from "components/Sidebar"
import Video from "pages/video"
import Header from "components/Header"
import { selectUserData } from "redux/user"
import { getCaregivers as fetchCaregivers } from "redux/onboarding"
import { selectCommonState } from "redux/common/selectors"
import {
  getCaregiverId,
  fetchCaregiverPublicData as getCaregiverPublicData,
} from "redux/caregiver"
import isEmpty from "lodash.isempty"
import Axios from "axios"
import LoadingScreen from "components/LoadingScreen"
import Cookie from "js-cookie"
import config from "../config"
import * as serviceWorker from "../serviceWorker"

const caregiversCacheTimeInMs = 5 * 60 * 1000

const useStyles = makeStyles(() => ({
  root: (height) => ({
    height,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }),
}))

const AuthRoute = ({
  loading,
  user,
  caregiverId,
  caregivers,
  caregiversFetchTime,
  caregiversLoading,
  fetchCaregiverPublicData,
  getCaregivers,
  children,
  ...rest
}) => {
  const [height, setHeight] = useState(window.innerHeight - 0.1)
  const classes = useStyles(height)
  const handleHeight = useCallback((e) => {
    setHeight(e.target.innerHeight)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleHeight)
    return () => {
      window.removeEventListener("resize", handleHeight)
    }
  }, [handleHeight])

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      // any valid IP address (when testing on mobile phones)
      window.location.hostname.match(
        /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi
      )
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (!isEmpty(user) && !user?.onboardingFinished) {
    // fetch caregivers early to fasten the loading of matchmaking
    if (
      !caregiversLoading &&
      (!caregivers ||
        Date.now() - caregiversFetchTime > caregiversCacheTimeInMs)
    ) {
      getCaregivers(user.id)
    }
    const hasSelectedCaregiverOrBookedMeeting =
      caregiverId || user.meeting?.start
    if (hasSelectedCaregiverOrBookedMeeting) {
      if (!caregiverId) {
        fetchCaregiverPublicData(user.meeting.caregiverId)
      }
      return (
        <Header>
          <Sidebar />
          <Video />
        </Header>
      )
    }
    return (
      <EmptyHeader>
        <Onboarding />
      </EmptyHeader>
    )
  }
  if (!isEmpty(user)) {
    return children
  }

  if (isLocalhost === false) {
    const { origin } = window.location
    let redirectionError = false
    let channelId = ""
    let errorMessage = ""
    if (origin.includes("test")) {
      if (!config.redirect_url.includes("test")) {
        redirectionError = true
        channelId = config.slackErrorsTestChannelId
        errorMessage = `Wrong PATIENT REDIRECTION URL ${config.redirect_url} used in Test environment!`
      }
    } else if (config.redirect_url.includes("test")) {
      redirectionError = true
      channelId = config.slackErrorsProdChannelId
      errorMessage = `Wrong PATIENT REDIRECTION URL ${config.redirect_url} used in Production environment!`
    }
    if (redirectionError) {
      const apiUrl = origin.replace("patient", "api")
      Axios.post(
        `${apiUrl}/slack/sendMessage`,
        {
          channelId,
          message: errorMessage,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
    }
  }

  if (serviceWorker.isRegistered()) {
    serviceWorker.unregister()
  }

  Cookie.remove("patient", { domain: config.cookie_path })
  sessionStorage.removeItem("patient")
  window.location.replace(config.redirect_url)
  return (
    <div className={classes.root}>
      <CircularProgress size={50} color="primary" />
    </div>
  )
}

const mapStateToProps = (state) => {
  const user = selectUserData(state)
  const { loading } = selectCommonState(state)
  const { caregiverId } = getCaregiverId(state)
  const { chooseCaregiver } = state.onboarding
  const {
    caregivers,
    caregiversFetchTime,
    loading: caregiversLoading,
  } = chooseCaregiver

  return {
    loading,
    user,
    caregiverId,
    caregivers,
    caregiversFetchTime,
    caregiversLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCaregiverPublicData: (userId) =>
    dispatch(getCaregiverPublicData(userId)),
  getCaregivers: (userId) => dispatch(fetchCaregivers(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute)
