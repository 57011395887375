import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { differenceInHours } from "date-fns"
import { LATE_CANCELLATION_FEE } from "utils/constants"

const MIN_HOURS_BEFORE_LATE_CANCELLATION = 24

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
      color: theme.palette.white,
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none",
    },
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1.25),
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const CancelMeetingConfirmationDialog = ({
  onClose,
  openDialog,
  onMeetingCancellation,
  videoMeetingStart,
  error,
}) => {
  const classes = useStyles()
  const isLateCancellation =
    differenceInHours(new Date(videoMeetingStart), new Date()) <
    MIN_HOURS_BEFORE_LATE_CANCELLATION

  return (
    <Dialog open={openDialog} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Avboka videosamtal</DialogTitle>
      <DialogContent>
        {isLateCancellation && (
          <Typography variant="body2">
            Vid avbokning mindre än 24 timmar innan bokad tid eller vid
            uteblivande skickas en{" "}
            <span className={classes.boldText}>
              faktura på {LATE_CANCELLATION_FEE} kronor
            </span>
            .
          </Typography>
        )}
        <Typography variant="body2" mt={2}>
          Är du säker på att du vill avboka?
        </Typography>
        {error && (
          <Typography
            className={classes.errorMessage}
            variant="caption"
            component="p"
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Nej
        </Button>
        <Button
          onClick={(e) => {
            e.target.disabled = true
            onMeetingCancellation({ isLateCancellation })
          }}
          className={classes.confirmButton}
          variant="contained"
        >
          Avboka
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelMeetingConfirmationDialog
