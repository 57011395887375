import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
      color: theme.palette.white,
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
  },
}))

const LeaveMeetingConfirmationDialog = ({ onClose, openDialog, onConfirm }) => {
  const classes = useStyles()
  return (
    <Dialog open={openDialog} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Lämna videosamtal</DialogTitle>
      <DialogContent>Vill du lämna videosamtal?</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Nej
        </Button>
        <Button onClick={onConfirm} className={classes.confirmButton}>
          Lämna
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LeaveMeetingConfirmationDialog
