import { useEffect, useState } from "react"
import {
  Card,
  Typography,
  Avatar,
  Box,
  Button,
  Hidden,
  Badge,
} from "@mui/material"
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined"
import { makeStyles } from "@mui/styles"
import { translateExpertise } from "utils/translateExpertise"
import {
  NavigateBefore as NavigateBeforeIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material"
import isValidVideoUrl from "utils/isValidVideoUrl"
import Vimeo from "@u-wave/react-vimeo"
import { isMobile } from "react-device-detect"
import { getFirstLetter } from "utils/getFirstLetter"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    boxShadow: "none",
    width: "60%",
    margin: `0 auto ${theme.spacing(3)}`,
    paddingTop: theme.spacing(12),
    [theme.breakpoints.down("lg")]: { width: "80%" },
    [theme.breakpoints.down("md")]: { width: "90%" },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 10),
      paddingTop: 0,
      width: "100%",
      flexDirection: "column",
      position: "initial",
    },
  },
  avatarContainer: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      height: "auto",
      width: "auto",
    },
  },
  infoContainer: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0, 6, 1, 0),
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  avatar: {
    position: "relative",
    alignSelf: "center",
    width: "250px",
    height: "250px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
      width: "100%",
      height: "50vh",
      cursor: "pointer",
    },
  },
  hidden: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      opacity: 0,
    },
  },
  badgeContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: "100%",
    },
  },
  badgeContentHidden: {
    height: "0",
    display: "none",
  },
  videoPlayer: {
    position: "absolute",
    zIndex: 10,
    width: "100%",
    height: "50vh",
  },
  videoPlayerMobile: {
    zIndex: 10,
    width: "100%",
    height: "50vh",
    background: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    "& div": {
      height: "100%",
      padding: "0 !important",
    },
  },
  playIcon: {
    width: "140px",
    height: "140px",
    opacity: "0.6",
  },
  videoContainer: {
    display: "none",
  },
  caregiverInfo: {
    width: "100%",
  },
  caregiverName: {
    fontSize: "1.65rem",
    fontWeight: "500",
    wordBreak: "break-word",
  },
  caregiverRole: {
    fontSize: "1rem",
    color: theme.palette.dark_gray,
    marginBottom: theme.spacing(2),
  },
  longDescContainer: {
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(2),
  },
  longDescText: {
    fontSize: "0.9rem",
    fontWeight: 400,
  },
  subtitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  listContainer: {
    color: theme.palette.default.light,
    fontSize: "0.9rem",
    marginBottom: theme.spacing(2),
  },
  stickyButtonContainer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(0, 1, 2),
    backgroundColor: "white",
    "& button": {
      textTransform: "none",
      borderRadius: theme.spacing(12.5),
      width: "95%",
      fontSize: "1rem",
      lineHeight: 2.1,
    },
  },
  fadeContainer: {
    height: "40px",
    background: "rgba(255, 255, 255, 0.8)",
    position: "fixed",
    left: 0,
    bottom: "40px",
    width: "100%",
  },
  backIcon: {
    position: "absolute",
    top: theme.spacing(3),
    left: 0,
    textTransform: "none",
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main_light,
    },
    "& svg": { width: "30px", height: "30px" },
    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      top: theme.spacing(1.9),
      left: theme.spacing(2),
      zIndex: 1202,
    },
    [theme.breakpoints.down(330)]: {
      left: theme.spacing(1),
    },
  },
  findTimeButton: {
    textTransform: "none",
    borderRadius: theme.spacing(12.5),
    width: "100%",
    marginTop: theme.spacing(2),
    boxShadow: "none",
    fontSize: "1rem",
    lineHeight: 2.1,
    "&:hover": {
      backgroundColor: theme.palette.primary.main_light,
    },
  },
}))

const Skills = ({ expertise }) => {
  const translatedExpertise = expertise.map(
    (exp) => translateExpertise(exp) || exp
  )
  return translatedExpertise.join(" | ")
}

const CaregiverInfo = ({ caregiver, goBack, setCaregiver }) => {
  const classes = useStyles()
  const {
    firstName = "",
    lastName = "",
    avatarUrl = "",
    longDesc = "",
    expertise = [],
    languages = [],
    videoUrl,
  } = caregiver

  const [isVideoPlaying, setIsVideoPlaying] = useState(!isMobile)
  const [playTime, setPlayTime] = useState(0)
  const [videoId, setVideoId] = useState("")

  useEffect(() => {
    if (videoUrl) {
      const regex = /\/(\d+)/
      const extractedVideoId = videoUrl.match(regex)

      setPlayTime(0)

      if (extractedVideoId && extractedVideoId[1]) {
        const id = extractedVideoId[1]
        setVideoId(id)
      }
    }
  }, [videoUrl])

  const handleEndVideo = () => {
    setIsVideoPlaying(false)
    setPlayTime(0)
  }

  return (
    <Card className={classes.root} data-testid="caregiver_info">
      <Hidden smDown>
        <Button
          className={classes.backIcon}
          color="inherit"
          onClick={() => goBack()}
          startIcon={<NavigateBeforeIcon />}
        >
          Tillbaka
        </Button>
      </Hidden>
      <Hidden smUp>
        <ArrowBackIcon
          fontSize="large"
          color="primary"
          className={classes.backIcon}
          onClick={() => goBack()}
        />
      </Hidden>
      <Box className={classes.avatarContainer}>
        <Hidden smUp>
          {videoUrl ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "center", horizontal: "center" }}
              className={[
                classes.badgeContent,
                isVideoPlaying ? classes.badgeContentHidden : "",
              ]}
              badgeContent={
                <PlayCircleFilledOutlinedIcon
                  className={[
                    classes.playIcon,
                    isVideoPlaying ? classes.hidden : "",
                  ]}
                  fontSize="large"
                />
              }
              onClick={() => setIsVideoPlaying(true)}
            >
              <Avatar
                src={avatarUrl}
                className={[
                  classes.avatar,
                  isVideoPlaying ? classes.hidden : "",
                ]}
                variant="square"
                onClick={() => setIsVideoPlaying(true)}
              />
            </Badge>
          ) : (
            <Avatar
              src={avatarUrl}
              className={classes.avatar}
              variant="square"
            />
          )}
        </Hidden>
        <Hidden smDown>
          <Box className={classes.caregiverInfo}>
            <Typography className={classes.caregiverName}>
              {firstName} {getFirstLetter(lastName)}
            </Typography>
            <Typography className={classes.caregiverRole}>
              Leg. Psykolog
            </Typography>
          </Box>
          <Avatar src={avatarUrl} className={classes.avatar} variant="square" />
          {isVideoPlaying && videoUrl && isValidVideoUrl(videoUrl) && (
            <Hidden className={classes.videoContainer} smDown>
              <Vimeo
                video={videoId}
                autoplay={false}
                start={playTime}
                onEnd={handleEndVideo}
                width={250}
              />
            </Hidden>
          )}
        </Hidden>
        {isVideoPlaying && videoUrl && isValidVideoUrl(videoUrl) && (
          <Hidden smUp>
            <Vimeo
              video={videoId}
              autoplay={isVideoPlaying}
              start={playTime}
              className={classes.videoPlayerMobile}
              onEnd={handleEndVideo}
              responsive
            />
          </Hidden>
        )}
      </Box>
      <Box className={classes.infoContainer}>
        <Hidden smUp>
          <Typography className={classes.caregiverName}>
            {firstName} {getFirstLetter(lastName)}
          </Typography>
          <Typography className={classes.caregiverRole}>
            Leg. Psykolog
          </Typography>
        </Hidden>
        {longDesc && (
          <Box className={classes.longDescContainer}>
            <Typography className={classes.longDescText}>{longDesc}</Typography>
          </Box>
        )}
        {expertise.length > 0 && (
          <>
            <Typography className={classes.subtitle}>
              Behandlingsområden
            </Typography>
            <Typography className={classes.listContainer}>
              <Skills expertise={expertise} />
            </Typography>
          </>
        )}
        {languages.length > 0 && (
          <>
            <Typography className={classes.subtitle}>Språk</Typography>
            <Typography className={classes.listContainer}>
              {languages.join(" | ")}
            </Typography>
          </>
        )}
        <Hidden smDown>
          <Box>
            <Button
              className={classes.findTimeButton}
              variant="contained"
              onClick={() => setCaregiver(caregiver)}
            >
              Hitta en tid
            </Button>
          </Box>
        </Hidden>
      </Box>
      <Hidden smUp>
        <div className={classes.fadeContainer} />
        <div className={classes.stickyButtonContainer}>
          <Button variant="contained" onClick={() => setCaregiver(caregiver)}>
            Hitta en tid
          </Button>
        </div>
      </Hidden>
    </Card>
  )
}

export default CaregiverInfo
