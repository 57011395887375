import { useState } from "react"
import { Box, Link, Typography } from "@mui/material"
import {
  Videocam as VideocamIcon,
  EventBusy as EventBusyIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { isSameMonth, format, differenceInMinutes } from "date-fns"
import { sv } from "date-fns/locale"
import VideoPaymentModal from "components/VideoPaymentModal"
import FrikortModal from "components/FrikortModal"
import { sessionType } from "utils/sessionType"
import { showAlert } from "redux/common"
import { useDispatch } from "react-redux"
import getUserAge from "utils/getUserAge"
import isFreeCardValid from "utils/isFreeCardValid"

const useStyles = makeStyles((theme) => ({
  root: ({
    type,
    bannerType,
    videoMeetingPaymentLink,
    videoMeetingIsPaid,
  }) => ({
    backgroundColor:
      // eslint-disable-next-line no-nested-ternary
      type === bannerType.ABSENCE
        ? [theme.palette.warning.main]
        : videoMeetingPaymentLink && !videoMeetingIsPaid
        ? [theme.palette.warning.dark] // Orange for patient booking
        : "#FAEEDD", // Blue for caregviver (invoice) booking
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
    color: "white",
    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
    },
  }),
  bannerContent: {
    display: "inherit",
    alignItems: "flex-start",
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
  },
  bannerText: {
    fontSize: "1rem",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
      textAlign: "left",
    },
  },
  invoiceBannerText: {
    color: theme.palette.primary.main,
  },
}))

const dateFormat = "d MMM"

const Banner = ({
  caregiverFirstName,
  absence,
  daysUntilAbsence,
  absenceDuration,
  videoMeetingPaymentLink,
  videoMeetingHasCustomPaymentExpiration,
  type,
  bannerType,
  videoMeetingId,
  videoMeetingStart,
  videoMeetingCreatedTime,
  videoMeetingIsPaid,
  freeCard,
  socialSecurity,
}) => {
  const classes = useStyles({
    type,
    bannerType,
    videoMeetingPaymentLink,
    videoMeetingIsPaid,
  })
  const [openVideoPaymentModal, setOpenVideoPaymentModal] = useState(false)
  const [openFrikortModal, setOpenFrikortModal] = useState(false)
  const dispatch = useDispatch()

  const getAbsenceHeader = () => {
    let header = `${caregiverFirstName} ${
      daysUntilAbsence <= 0 ? " är ledig " : " kommer vara ledig "
    }`
    if (absenceDuration === 0) {
      header += format(new Date(absence.startAt), dateFormat, { locale: sv })
    } else {
      header += format(
        new Date(absence.startAt),
        isSameMonth(new Date(absence.startAt), new Date(absence.endAt))
          ? "d"
          : dateFormat,
        { locale: sv }
      )
      header += " - "
      header += format(new Date(absence.endAt), dateFormat, { locale: sv })
    }

    header = header.replace(/\./g, "")

    return header
  }

  if (type === bannerType.ABSENCE) {
    return (
      <Box className={classes.root}>
        <Box className={classes.bannerContent}>
          <EventBusyIcon />
          <Typography variant="h6" className={classes.bannerText}>
            {getAbsenceHeader()}
          </Typography>
        </Box>
      </Box>
    )
  }

  // Patient booking banner
  if (videoMeetingPaymentLink && !videoMeetingIsPaid) {
    const minutesBetweenMeetingCreationAndStart = differenceInMinutes(
      new Date(videoMeetingStart),
      new Date(videoMeetingCreatedTime)
    )

    const minutesDefaultPaymentExpiration = 25
    const minutesBetweenMeetingCreationAndStartThreshold = 20
    const minutesPaymentExpirationBeforeThreshold = 5
    const minutesPaymentExpirationAfterThreshold = 10

    // eslint-disable-next-line no-nested-ternary
    const minutesUntilPaymentExpiration = videoMeetingHasCustomPaymentExpiration
      ? minutesBetweenMeetingCreationAndStart <
        minutesBetweenMeetingCreationAndStartThreshold
        ? minutesPaymentExpirationBeforeThreshold
        : minutesPaymentExpirationAfterThreshold
      : minutesDefaultPaymentExpiration

    return (
      <Box className={classes.root}>
        <Link
          underline="always"
          className={classes.bannerContent}
          color="white"
          component="button"
          variant="h6"
          onClick={() => setOpenVideoPaymentModal(true)}
        >
          <VideocamIcon />
          <Typography variant="subtitle2" className={classes.bannerText}>
            Din tid är reserverad i {minutesUntilPaymentExpiration} min.
            Vänligen bekräfta bokningen nedan för att behålla tiden.
          </Typography>
        </Link>
        <VideoPaymentModal
          open={openVideoPaymentModal}
          close={() => {
            setOpenVideoPaymentModal(false)
          }}
          onPaymentDecision={() => {
            setOpenVideoPaymentModal(false)
            setOpenFrikortModal(true)
          }}
          showAlert={({ alertType, message }) =>
            dispatch(showAlert({ alertType, message }))
          }
          videoMeetingPaymentLink={videoMeetingPaymentLink}
        />
        <FrikortModal
          open={openFrikortModal}
          close={() => setOpenFrikortModal(false)}
          goBack={() =>
            openFrikortModal
              ? (setOpenFrikortModal(false), setOpenVideoPaymentModal(true))
              : null
          }
          sessionType={sessionType.VIDEO}
          videoMeetingId={videoMeetingId}
        />
      </Box>
    )
  }

  const userAge = getUserAge(socialSecurity)
  const freeAgeUser = userAge < 20 || userAge >= 85
  const freeCardUser = isFreeCardValid(freeCard?.expirationDate)

  // Bookings with caregiver will always be paid, since it is the invoice flow
  const isBookedByCaregiver =
    !videoMeetingPaymentLink &&
    videoMeetingIsPaid &&
    !freeAgeUser &&
    !freeCardUser
  const showInvoiceBannerForMinutes = 10
  const shouldShowInvoiceBanner =
    isBookedByCaregiver &&
    new Date(videoMeetingCreatedTime).getTime() +
      showInvoiceBannerForMinutes * 60 * 1000 >
      new Date().getTime()

  if (!shouldShowInvoiceBanner) {
    return null
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.bannerContent}
        sx={{ alignItems: "center !important" }}
      >
        <VideocamIcon className={classes.invoiceBannerText} />
        <Typography
          variant="subtitle2"
          className={[classes.bannerText, classes.invoiceBannerText]}
        >
          Din tid är bokad. Faktura skickas efter genomfört besök.
        </Typography>
      </Box>
    </Box>
  )
}

export default Banner
