import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { createVideoMeetingForFreeAgeOrFreeCardUser } from "api/createVideoMeetingForFreeAgeOrFreeCardUser"
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { format, isBefore, isSameDay } from "date-fns"
import { getFormattedDateWithDay } from "utils/getFormattedDateWithDay"
import { trackEvent } from "utils/trackEvent"
import getUserAge from "utils/getUserAge"
import posthog from "posthog-js"
import BookingConfirmationModal from "./BookingConfirmationModal"

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    height: "auto",
    margin: `${theme.spacing(3)} auto ${theme.spacing(2)}`,
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  timeSelector: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(-0.5),
    "& button": {
      padding: 0,
      "& svg": {
        width: "1.5em",
        height: "1.5em",
      },
    },
  },
  timeslotContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(2),
    "& button": {
      "&:hover, &:active, &:focus": {
        background: theme.palette.primary.main,
        color: theme.palette.white,
      },
    },
  },
  timeslotButton: {
    borderRadius: theme.spacing(2),
    width: "30%",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    lineHeight: "2.5",
  },
  timeslotDate: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 2),
    },
  },
}))

const TimeSlot = ({ date, onTimeSlotSelect, bookingDisabled }) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={classes.timeslotButton}
      onClick={onTimeSlotSelect}
      disabled={bookingDisabled}
    >
      {format(new Date(date), "HH:mm")}
    </Button>
  )
}

const AvailableVideoTimes = ({
  userId,
  userEmail,
  userPhone,
  userSocialSecurity,
  availabilities,
  caregiverFirstName,
  caregiverLastName,
  caregiverId,
  isFreeAgeUser,
  isFreeCardUser,
  freeCard,
  openPaymentModal,
  getUserProfile,
  showAlert,
  bookingDisabled,
  paidSessionNumber,
  onDateChange,
}) => {
  const [dateIndex, setDateIndex] = useState(0)
  const [isIncrementDisabled, setIsIncrementDisabled] = useState(
    availabilities.length === 1
  )
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(true)
  const [
    isFreeCardInvalidForSelectedTimeSlot,
    setIsFreeCardInvalidForSelectedTimeSlot,
  ] = useState(false)
  const [
    isFreeAgeInvalidForSelectedTimeSlot,
    setIsFreeAgeInvalidForSelectedTimeSlot,
  ] = useState(false)
  const [openBookingConfirmationModal, setOpenBookingConfirmationModal] =
    useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
  const classes = useStyles()
  const navigate = useNavigate()
  const timezoneOffset = new Date(
    availabilities[dateIndex]?.dayDate
  ).getTimezoneOffset()
  let timezoneOffsetMinutes
  let timezoneOffsetHours
  let timezoneOffsetSign
  if (timezoneOffset) {
    timezoneOffsetSign = timezoneOffset < 0 ? "+" : "-"
    const absoluteTimezoneOffset = Math.abs(timezoneOffset)
    timezoneOffsetMinutes = absoluteTimezoneOffset % 60
    timezoneOffsetHours = Math.floor(absoluteTimezoneOffset / 60)
  }

  useEffect(() => {
    if (onDateChange) onDateChange(dateIndex)
  }, [dateIndex, onDateChange])

  useEffect(() => {
    setIsIncrementDisabled(availabilities.length === 1)
  }, [availabilities])

  useEffect(() => {
    trackEvent("calendarDayView", {
      userId,
      availableSlots: JSON.stringify(availabilities[dateIndex]?.slots),
    })
  }, [userId])

  const incrementDate = () => {
    const newIndex = dateIndex + 1
    trackEvent("calendarDayView", {
      userId,
      availableSlots: JSON.stringify(availabilities[newIndex]?.slots),
    })
    setDateIndex(newIndex)
    setIsIncrementDisabled(newIndex === availabilities.length - 1)
    setIsDecrementDisabled(false)
  }

  const decrementDate = () => {
    const newIndex = dateIndex - 1
    trackEvent("calendarDayView", {
      userId,
      availableSlots: JSON.stringify(availabilities[newIndex]?.slots),
    })
    setDateIndex(newIndex)
    setIsDecrementDisabled(newIndex === 0)
    setIsIncrementDisabled(false)
  }

  const onTimeSlotSelect = (start, end, timeZone) => {
    trackEvent("selectTimeslot", {
      userId,
    })
    posthog.capture(
      "selectedTimeslotWithCaregiver",
      {
        numberPaidSessions: paidSessionNumber,
        caregiverId,
      },
      { sendFeatureFlags: true }
    )

    setSelectedTimeSlot({ start, end, timeZone })
    if (!freeCard?.expirationDate) {
      setIsFreeCardInvalidForSelectedTimeSlot(false)
    } else {
      const expirationDate = new Date(freeCard?.expirationDate)
      const meetingDate = new Date(start)

      const isValid =
        isBefore(meetingDate, expirationDate) ||
        isSameDay(meetingDate, expirationDate)

      setIsFreeCardInvalidForSelectedTimeSlot(isFreeCardUser && !isValid)
    }
    if (isFreeAgeUser) {
      const userAge = getUserAge(userSocialSecurity, start)
      setIsFreeAgeInvalidForSelectedTimeSlot(userAge >= 20 && userAge < 85)
    }
    setOpenBookingConfirmationModal(true)
  }

  const createVideoMeeting = () => {
    const { start, timeZone: slotTimeZone } = selectedTimeSlot
    createVideoMeetingForFreeAgeOrFreeCardUser({
      caregiverId,
      start,
      slotTimeZone,
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then(async ({ data: responseData }) => {
        await getUserProfile(userId)
        setOpenBookingConfirmationModal(false)
        navigate(
          `/payment-successful?type=20&ikbtInvite=${responseData?.ikbtInvite}`
        )
      })
      .catch((error) => {
        if (error?.response?.status === 405) {
          window.location.reload()
        } else {
          let errorMessage =
            "Tiden är dessvärre inte längre tillgänglig. Var god och välj en annan tid."

          if (error?.response?.status === 401) {
            errorMessage = "Tiden kan ej bokas så nära inpå mötet."
          } else if ([403, 503].includes(error?.response?.status)) {
            errorMessage = error?.response?.data
              ? error.response.data
              : errorMessage
          }
          showAlert({ type: "error", message: errorMessage })
        }
      })
  }

  const continueWithBooking = () => {
    setOpenBookingConfirmationModal(false)
    return (isFreeAgeUser && !isFreeAgeInvalidForSelectedTimeSlot) ||
      (isFreeCardUser && !isFreeCardInvalidForSelectedTimeSlot)
      ? createVideoMeeting()
      : openPaymentModal(
          selectedTimeSlot?.start,
          selectedTimeSlot?.end,
          selectedTimeSlot?.timeZone
        )
  }

  return (
    <>
      <Box className={classes.dataContainer}>
        <Box className={classes.timeSelector}>
          <Button disabled={isDecrementDisabled} onClick={decrementDate}>
            <KeyboardArrowLeftIcon />
          </Button>
          <Typography fontWeight="bold" className={classes.timeslotDate}>
            {getFormattedDateWithDay(availabilities[dateIndex]?.dayDate)}
          </Typography>
          <Button disabled={isIncrementDisabled} onClick={incrementDate}>
            <KeyboardArrowRightIcon />
          </Button>
        </Box>
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
          Tidszon: UTC
          {timezoneOffset
            ? `${timezoneOffsetSign}${timezoneOffsetHours}${
                timezoneOffsetMinutes ? `:${timezoneOffsetMinutes}` : ""
              }`
            : ""}
        </Typography>
        <Box className={classes.timeslotContainer}>
          {availabilities[dateIndex]?.slots?.map((date, index) => (
            <TimeSlot
              key={index}
              date={date?.start}
              onTimeSlotSelect={() =>
                onTimeSlotSelect(date?.start, date?.end, date?.timeZone)
              }
              bookingDisabled={bookingDisabled}
            />
          ))}
        </Box>
      </Box>
      <BookingConfirmationModal
        openDialog={openBookingConfirmationModal}
        onClose={() => setOpenBookingConfirmationModal(false)}
        isFreeAgeUser={isFreeAgeUser}
        isFreeCardInvalidForSelectedTimeSlot={
          isFreeCardInvalidForSelectedTimeSlot
        }
        isFreeAgeInvalidForSelectedTimeSlot={
          isFreeAgeInvalidForSelectedTimeSlot
        }
        onContinueWithBooking={() => {
          continueWithBooking()
        }}
        selectedTimeSlot={selectedTimeSlot?.start}
        caregiverFirstName={caregiverFirstName}
        caregiverLastName={caregiverLastName}
        userId={userId}
        userEmail={userEmail}
        userPhone={userPhone}
        freeCard={freeCard}
        numberPaidSessions={paidSessionNumber}
      />
    </>
  )
}

export default AvailableVideoTimes
