import React, { useEffect, createRef } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
import IdleTimer from "react-idle-timer"
import { Provider } from "react-redux"
import store from "redux/store"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { Hidden, CssBaseline } from "@mui/material"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import theme from "theme"
import Home from "pages/home"
import Chat from "pages/chat"
import Profile from "pages/profile"
import PaymentSuccess from "pages/paymentSuccess"
import PaymentCanceled from "pages/paymentCanceled"
import Header from "components/Header"
import BackHeader from "components/BackHeader"
import Sidebar from "components/Sidebar"
import BottomNavigation from "components/BottomNavigation"
import AdsTracker from "components/AdsTracker"
import { getUserFromCookieOrSessionStorage } from "utils/getUserFromCookieOrSessionStorage"
import Alert from "components/Alert"
import {
  logout,
  fetchUserNotifications,
  decrementUnfilledFormsNumber,
  resetUnreadMessagesNumber,
} from "redux/user"
import BeforeInstallPromptListenerWrapper from "components/BeforeInstallPromptListenerWrapper"
import Video from "pages/video"
import io from "socket.io-client"
import config from "config"
import { getUserChat } from "api/getUserChat"
import { postMessageInChat } from "redux/chat"
import { setSocket, showAlert } from "redux/common"
import { chatMessagesSeen } from "api/chatMessagesSeen"
import posthog from "posthog-js"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import AuthRoute from "./AuthRoute"
import withClearCache from "../clearCache"

// 60 minutes
const IDLE_TIMER = 1000 * 60 * 60
const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function MainApp() {
  const idleTimerRef = createRef()

  useEffect(() => {
    const getUserAndChatDataWithSocketInstanceCreation = async (dispatch) => {
      const patient = await getUserFromCookieOrSessionStorage(dispatch)
      if (patient) {
        const { token, id: userId } = patient
        posthog.identify(userId)
        let { data: chatData } = await getUserChat(userId)
        const socket = io(`${config.ws_url}?token=${token}`)
        socket.on("connect", () => {
          dispatch(setSocket(socket))
          socket.emit("ready for data", {})
        })

        socket.on("update", async ({ message }) => {
          const { pathname } = window.location
          if (!chatData.length) {
            const userChat = await getUserChat(userId)
            chatData = userChat.data
          }
          const [{ id: activeChatId }] = chatData

          if (message.chatId === activeChatId) {
            await dispatch(fetchUserNotifications(userId))
            if (pathname === "/messages") {
              await dispatch(postMessageInChat(message))
              chatMessagesSeen(message.chatId, userId)
              socket.emit("messageSeen", { chatId: message.chatId, userId })
              dispatch(resetUnreadMessagesNumber())
            }
          }
        })

        socket.on("form: filled", () => {
          dispatch(decrementUnfilledFormsNumber())
        })

        socket.on("meeting: booked", (data) => {
          if (data.isBookedWithInvoice && data.meetingStart) {
            const videoMeetingStartDate = new Date(data.meetingStart)
            const dateString = format(videoMeetingStartDate, "d MMM", {
              locale: sv,
            })
            const timeString = format(videoMeetingStartDate, "HH:mm", {
              locale: sv,
            })
            dispatch(
              showAlert({
                message: `Din psykolog har bokat ett videosamtal åt dig (${dateString} kl. ${timeString}).`,
              })
            )
            return
          }

          if (data.meetingStart) {
            const videoMeetingStartDate = new Date(data.meetingStart)
            const dateString = format(videoMeetingStartDate, "d MMM", {
              locale: sv,
            })
            const timeString = format(videoMeetingStartDate, "HH:mm", {
              locale: sv,
            })
            dispatch(
              showAlert({
                message: `Din psykolog har bokat ett videosamtal åt dig (${dateString} kl.${timeString}).`,
              })
            )
            return
          }

          dispatch(
            showAlert({
              type: "info",
              message:
                // eslint-disable-next-line max-len
                "Din psykolog har nu reserverat en tid åt dig.",
            })
          )
        })

        return () => {
          dispatch(setSocket(null))
          socket.disconnect()
        }
      }
    }
    getUserAndChatDataWithSocketInstanceCreation(store.dispatch)
  }, [])

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BeforeInstallPromptListenerWrapper>
              <Router>
                <AdsTracker />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <AuthRoute>
                        <Header>
                          <Sidebar />
                          <Home />
                          <Hidden smUp>
                            <BottomNavigation />
                          </Hidden>
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/messages"
                    element={
                      <AuthRoute>
                        <Header>
                          <Hidden smDown>
                            <Sidebar />
                          </Hidden>
                          <Chat />
                          <Hidden smUp>
                            <BottomNavigation />
                          </Hidden>
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/video"
                    element={
                      <AuthRoute>
                        <Header>
                          <Sidebar />
                          <Video />
                          <Hidden smUp>
                            <BottomNavigation />
                          </Hidden>
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <AuthRoute>
                        <BackHeader>
                          <Profile />
                        </BackHeader>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/payment-successful"
                    element={
                      <AuthRoute>
                        <Header>
                          <PaymentSuccess />
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route
                    path="/payment-canceled"
                    element={
                      <AuthRoute>
                        <Header>
                          <PaymentCanceled />
                        </Header>
                      </AuthRoute>
                    }
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Router>
            </BeforeInstallPromptListenerWrapper>

            <IdleTimer
              ref={idleTimerRef}
              element={document}
              onIdle={() => store.dispatch(logout())}
              timeout={IDLE_TIMER}
            />
            <Alert />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
}

export default App
